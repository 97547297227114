import { corpWorkdayCalendarService } from "@telia/b2b-rest-client";

export function nextAvailableWorkday(scopeId: string, offset: number) {
  return corpWorkdayCalendarService.DatePickerControllerService.getNextEligibleWorkday(
    scopeId,
    offset
  );
}

export function holidays(scopeId: string, year?: number) {
  return corpWorkdayCalendarService.DatePickerControllerService.holidays(scopeId, year);
}
