<template>
  <div class="owner-information">
    <telia-heading tag="h3" variant="subsection-100">{{
      type === "PRIVATE"
        ? t("numberMigrationConfiguration.ownerInformation.private.title")
        : t("numberMigrationConfiguration.ownerInformation.business.title")
    }}</telia-heading>
    <telia-p>
      {{
        type === "PRIVATE"
          ? t("numberMigrationConfiguration.ownerInformation.private.information")
          : t("numberMigrationConfiguration.ownerInformation.business.information")
      }}
    </telia-p>
  </div>
</template>

<script setup lang="ts">
import { translate } from "@telia/b2b-i18n";
interface Props {
  type: "PRIVATE" | "BUSINESS";
}

defineProps<Props>();
const t = translate;
</script>

<style scoped lang="scss">
@import "~@teliads/components/foundations/spacing/variables";

.owner-information {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-12;
  margin-bottom: $telia-spacing-12;
}
</style>
