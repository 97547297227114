<script setup lang="ts">
import { Ref } from "vue";
import { FlagProvider, UnleashClient } from "@unleash/proxy-client-vue";
import { useUnleashClientWithScopeId } from "@telia/b2b-unleash-client-vue3/dist/b2b-unleash-client-vue3.esm";
import B2bNumberMigrationConfiguration from "./b2b-number-migration-configuration.vue";

interface Props {
  blockTransfer: boolean;
  c2bUid?: string;
  disableForm: boolean;
  tscid: string;
}
const props = defineProps<Props>();

const client: Ref<UnleashClient | null> = useUnleashClientWithScopeId("b2b-number-migration");
</script>

<template>
  <FlagProvider v-if="client" :unleash-client="client" :start-client="false">
    <B2bNumberMigrationConfiguration
      :blockTransfer="blockTransfer"
      :c2bUid="c2bUid"
      :disable-form="disableForm"
      :tscid="tscid"
    />
  </FlagProvider>
</template>
