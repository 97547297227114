import B2bNumberMigrationConfiguration from "./b2b-number-migration-configuration.vue";
import OrganizationValidationMessage from "./components/organization-validation-message.vue";
import OriginSelector from "./components/origin-selector.vue";
import portinNew from "./components/portin-new.vue";
import Portin from "./components/portin.vue";
import Tooltip from "./components/tooltip.vue";
import Transfer from "./components/transfer.vue";
import ToggleProvider from "./toggle-provider.vue";
import MigrationDate from "./components/migration-date.vue";
import OwnerInformation from "./components/owner-information.vue";

const styles = mergeStylesOfComponents(
  B2bNumberMigrationConfiguration,
  OrganizationValidationMessage,
  OriginSelector,
  Portin,
  portinNew,
  Tooltip,
  Transfer,
  MigrationDate,
  OwnerInformation
);
ToggleProvider.styles = styles;

export default ToggleProvider;

function mergeStylesOfComponents(...components): string[] {
  return components.flatMap(({ styles }) => styles);
}
