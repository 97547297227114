<template>
  <div class="origin-selector">
    <b2x-radio-card
      t-id="origin-selector__private-select-option"
      class="origin-selector__button"
      name="private"
      id="private"
      value="PRIVATE"
      :label="t('numberMigrationConfiguration.originSelector.private')"
      :checked="selectedMigrationOrigin === 'PRIVATE'"
      @input="handleOriginSelection"
    ></b2x-radio-card>
    <b2x-radio-card
      t-id="origin-selector__organization-select-option"
      class="origin-selector__button"
      name="private"
      id="private"
      value="ORGANIZATION"
      :label="t('numberMigrationConfiguration.originSelector.organization')"
      :checked="selectedMigrationOrigin === 'ORGANIZATION'"
      @input="handleOriginSelection"
    ></b2x-radio-card>
  </div>
</template>

<script>
import { translateMixin } from "../locale";
import "@telia/b2x-radio-card";

export default {
  name: "origin-selector",
  mixins: [translateMixin],
  props: { disableForm: { type: Boolean } },
  data: () => ({
    selectedMigrationOrigin: "PRIVATE",
  }),
  created() {
    this.emitNumberOrigin();
  },
  methods: {
    handleOriginSelection(event) {
      this.selectedMigrationOrigin = event.target.value;
      this.emitNumberOrigin();
    },
    emitNumberOrigin() {
      this.$emit("number-origin", this.selectedMigrationOrigin);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
.origin-selector {
  margin-bottom: $telia-spacing-24;
  display: flex;
  gap: $telia-spacing-12;
  &__button {
    flex-basis: 50%;
  }
}
</style>
