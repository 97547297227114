<template>
  <div>
    <template v-if="isPrivateNumberMigration">
      <div
        class="transfer__number-migration-configuration__head tc-gr__col--12 transfer__input-tooltip"
        t-id="transfer__e-signing-information"
      >
        <strong>{{ t("tooltip.eSignature") }}</strong>
        <div class="transfer__input-tooltip__tooltip">
          <tooltip
            t-id="transfer__input-tooltip__tooltip"
            icon="info"
            icon-size="md"
            :text-left="{ boxLeft: -70, arrowLeft: 24 }"
            :ally-title="
              t('tooltip.moreInformation', {
                name: t('tooltip.eSignature'),
              })
            "
          >
            <template #content>
              <span>
                {{ t("tooltip.eSignInfo") }}
              </span>
            </template>
          </tooltip>
        </div>
        <telia-text-input
          t-id="transfer__private-email"
          key="transfer__private-email"
          :disabled="disableForm"
          :label="t('numberMigrationConfiguration.emailLabel')"
          required
          :required-error-message="t('numberMigrationConfiguration.validations.required')"
          type="email"
          :pattern="validationPatterns.email"
          :pattern-error-message="t('numberMigrationConfiguration.validations.email')"
          @input="handleConfigurationUpdate('email', $event.target.value)"
        />
      </div>
    </template>
    <template v-else>
      <telia-text-input
        t-id="transfer__organization-ssn"
        key="transfer__organization-ssn"
        :disabled="disableForm || validSignatory"
        :label="t('numberMigrationConfiguration.ssnOrganizationLabel')"
        required
        :required-error-message="t('numberMigrationConfiguration.validations.required')"
        :pattern="validationPatterns.ssn"
        :pattern-error-message="t('numberMigrationConfiguration.validations.ssn')"
        @input="handleConfigurationUpdate('ssn', $event.target.value)"
      />
      <telia-button
        t-id="transfer__validate-signatory-button"
        :disabled="disableValidateSignatoryButton"
        variant="tertiary-purple"
        size="sm"
        @click="validateOrganizationSignatoryRequest"
      >
        <span v-show="!pendingSignatoryValidation">
          {{ t("numberMigrationConfiguration.validateSignatoryButton") }}
        </span>
        <telia-slot-group>
          <template v-if="pendingSignatoryValidation">
            <div class="transfer__validate-signatory__spinner">
              <b2x-spinner />
            </div>
          </template>
        </telia-slot-group>
      </telia-button>
      <organization-validation-message
        t-id="transfer__organization-validation-message"
        v-if="hasValidSignatoryResult"
        :valid-signatory="validSignatory"
      />
      <template v-if="validSignatory">
        <div class="transfer__email-signature transfer__input-tooltip">
          <div class="transfer__input-tooltip__tooltip">
            <tooltip
              t-id="transfer__organization-email-signature__tooltip"
              :textRight="{
                boxRight: '-70',
                arrowRight: '14',
              }"
            >
              <span slot="content">
                {{ t("numberMigrationConfiguration.emailSignatureTooltipContent") }}
              </span>
            </tooltip>
          </div>
          <telia-text-input
            t-id="transfer__organization-email"
            key="transfer__organization-email"
            :disabled="disableForm"
            :label="t('numberMigrationConfiguration.emailOrganizationLabel')"
            required
            :required-error-message="t('numberMigrationConfiguration.validations.required')"
            type="email"
            :pattern="validationPatterns.email"
            :pattern-error-message="t('numberMigrationConfiguration.validations.email')"
            @input="handleConfigurationUpdate('email', $event.target.value)"
          />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { validateOrganizationSignatory } from "../services/number-migration-service";
import OrganizationValidationMessage from "./organization-validation-message";
import Tooltip from "./tooltip.vue";
import {
  isValidEmailPattern,
  isValidSocialSecurityNumberPattern,
  validateNumberConfiguration,
} from "../utils/validations";
import { translateMixin } from "../locale";

export default {
  name: "transfer",
  mixins: [translateMixin],
  components: { OrganizationValidationMessage, Tooltip },
  props: {
    disableForm: { type: Boolean },
    organization: { type: String },
    scopeId: { type: String },
  },
  data: () => ({
    error: {
      organizationSignatoryRequest: false,
    },
    validationPatterns: {
      email: isValidEmailPattern.source,
      ssn: isValidSocialSecurityNumberPattern.source,
    },
    transferConfiguration: {
      email: null,
      organizationNumber: null,
      ssn: null,
    },
    lastValidatedSignatory: { ssn: null, organizationNumber: null },
    validSignatory: null,
    pendingSignatoryValidation: false,
  }),
  async mounted() {
    this.transferConfiguration.organizationNumber = this.organization;
    this.emitConfigurationState();
  },
  computed: {
    disableValidateSignatoryButton() {
      return (
        this.validSignatory ||
        !validateNumberConfiguration(
          "organizationNumber",
          this.transferConfiguration.organizationNumber
        ) ||
        !validateNumberConfiguration("ssn", this.transferConfiguration.ssn) ||
        this.pendingSignatoryValidation ||
        !this.isSignatoryInputUpdated
      );
    },
    hasOrganizationSignatoryRequestError() {
      return (
        this.error.organizationSignatoryRequest &&
        this.validSignatory == null &&
        !this.pendingSignatoryValidation
      );
    },
    hasValidSignatoryResult() {
      return this.validSignatory !== null;
    },
    isPrivateNumberMigration() {
      return !this.organization;
    },
    isSignatoryInputUpdated() {
      return (
        this.transferConfiguration.ssn !== this.lastValidatedSignatory.ssn ||
        this.transferConfiguration.organizationNumber !==
          this.lastValidatedSignatory.organizationNumber
      );
    },
    isValid() {
      if (this.isPrivateNumberMigration) {
        return validateNumberConfiguration("email", this.transferConfiguration.email);
      } else {
        return Object.keys(this.validationPatterns)
          .map((key) => validateNumberConfiguration(key, this.transferConfiguration[key]))
          .every(Boolean);
      }
    },
  },
  methods: {
    emitConfigurationState() {
      this.$emit("configuration-state", {
        configuration: this.transferConfiguration,
        valid: this.isValid,
      });
    },
    handleConfigurationUpdate(key, value) {
      this.transferConfiguration[key] = value;
      this.emitConfigurationState();
    },
    emitSignatoryRequestError() {
      this.$emit("signatory-request-error", this.hasOrganizationSignatoryRequestError);
    },
    resetSignatoryValidation() {
      this.error.organizationSignatoryRequest = false;
      this.validSignatory = null;
      this.lastValidatedSignatory = { ssn: null, organizationNumber: null };
    },
    validateOrganizationSignatoryRequest() {
      this.validSignatory = null;
      this.pendingSignatoryValidation = true;
      this.error.organizationSignatoryRequest = false;

      validateOrganizationSignatory(
        this.transferConfiguration.organizationNumber,
        this.transferConfiguration.ssn,
        this.scopeId
      )
        .then((res) => {
          this.validSignatory = res.valid;
        })
        .catch(() => {
          this.error.organizationSignatoryRequest = true;
        })
        .finally(() => {
          this.pendingSignatoryValidation = false;
          this.lastValidatedSignatory = {
            organizationNumber: this.transferConfiguration.organizationNumber,
            ssn: this.transferConfiguration.ssn,
          };
        });
    },
  },
  watch: {
    hasOrganizationSignatoryRequestError: "emitSignatoryRequestError",
  },
};
</script>

<style lang="scss" scoped>
.transfer {
  &__input-tooltip {
    position: relative;
    &__tooltip {
      position: absolute;
      right: 1rem;
    }
  }
}
</style>
