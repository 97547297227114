export default {
  methods: {
    formatMsisdn(msisdn) {
      return msisdn?.replace(/^[4][6](\d{2})(\d{3})(\d{2})(\d{2})$/, "0$1-$2 $3 $4");
    },
    formatToMsisdn(number) {
      return number
        ?.replace(/^07/, "467")
        .replace(/^\+/, "")
        .replace(/^00/, "")
        .replace(/[\s-]/g, "");
    },
  },
};
