<template>
  <div
    :class="[
      'organization-validation-message__signatory-message ',
      validSignatory
        ? 'organization-validation-message__signatory-message--green'
        : 'organization-validation-message__signatory-message--red',
    ]"
  >
    <telia-icon
      v-if="validSignatory"
      key="check-icon"
      class="check-circle"
      name="check-circle"
      size="lg"
      ally-title="check-circle icon"
    ></telia-icon>
    <telia-icon
      v-if="!validSignatory"
      key="error-icon"
      class="error"
      name="error"
      size="lg"
      ally-title="error icon"
    ></telia-icon>
    <telia-p
      class="organization-validation-message__signatory-message__result"
      t-id="organization-validation-message__signatory-message-result"
    >
      {{
        validSignatory
          ? t("numberMigrationConfiguration.validSignatory.match")
          : t("numberMigrationConfiguration.validSignatory.noMatch")
      }}
    </telia-p>
  </div>
</template>

<script>
import { translateMixin, translateSetup } from "../locale";
export default {
  name: "OrganizationValidationMessage",
  mixins: [translateMixin],
  props: {
    validSignatory: { Boolean, required: true },
  },
  async created() {
    translateSetup();
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";

.organization-validation-message {
  &__signatory-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: $telia-spacing-8 $telia-spacing-8 $telia-spacing-16 $telia-spacing-8;

    &__result {
      margin-left: $telia-spacing-8;
      margin-bottom: 0;
    }

    &--green {
      color: $telia-green-600;
    }

    &--red {
      color: $telia-red-600;
    }
  }
}
</style>
