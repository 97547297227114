<template>
  <telia-notification
    heading-tag="h3"
    class=""
    variant="inline"
    :status="alertMessage.status"
    :t-id="`message-presenter__${alertMessage.tId}`"
  >
    <span slot="heading">
      <telia-visually-hidden>{{ t(alertAccessibilityTitle) }}</telia-visually-hidden>
      {{ t(alertTitle) }}
    </span>
    <telia-text-spacing slot="content">
      <telia-p>
        {{ t(alertMessage.message, additionalData) }}
        <template v-if="alertLink">
          <b2x-sspa-link
            t-id="message-presenter__link"
            target="_blank"
            :href="alertLink.url"
            :link-title="alertLinkTitle"
          >
            {{ t(alertLink.message) }}
          </b2x-sspa-link>
        </template>
      </telia-p>
      <slot name="additionalContent"></slot>
    </telia-text-spacing>
  </telia-notification>
</template>

<script>
import { translateMixin, translateSetup } from "../../locale";

export default {
  name: "MessagePresenter",
  mixins: [translateMixin],
  props: {
    additionalData: { type: Object, default: () => ({}) },
    messages: { type: Object, required: true },
    messageType: { type: String, required: true },
  },
  data: () => ({}),
  async created() {
    translateSetup();
  },
  computed: {
    alertAccessibilityTitle() {
      return this.alertMessage?.accessibleTitle || this.alertTitle || "";
    },
    alertMessage() {
      return this.messages[this.messageType] || null;
    },
    alertTitle() {
      return this.alertMessage?.title;
    },
    alertLink() {
      return this.alertMessage?.link;
    },
    alertLinkTitle() {
      return this.alertLink?.accessibleTitle || "";
    },
  },
};
</script>

<style scoped></style>
