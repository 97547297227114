export const messages = {
  portinOngoing: {
    tId: "number-migration__port-in-ongoing-info",
    title: "numberMigrationConfiguration.alerts.notSupportedTitle",
    status: "warning",
    message: "numberMigrationConfiguration.alerts.portinOngoingMessage",
    accessibleTitle: "numberMigrationConfiguration.alerts.accessibleTitle.warning",
  },
  portinOngoingSameOrg: {
    tId: "number-migration__port-in-ongoing-same-org-info",
    title: "numberMigrationConfiguration.alerts.notSupportedTitle",
    status: "warning",
    message: "numberMigrationConfiguration.alerts.portinOngoingSameOrgMessage",
    accessibleTitle: "numberMigrationConfiguration.alerts.accessibleTitle.warning",
  },
  portinNotPossible: {
    tId: "number-migration__validation",
    title: "numberMigrationConfiguration.alerts.notSupportedTitle",
    status: "warning",
    message: "numberMigrationConfiguration.alerts.portinNotPossible",
    accessibleTitle: "numberMigrationConfiguration.alerts.accessibleTitle.warning",
  },
  transferNotPossible: {
    tId: "number-migration__validation-error",
    title: "numberMigrationConfiguration.alerts.defaultTitle",
    status: "warning",
    message: "numberMigrationConfiguration.alerts.transferNotPossible",
    accessibleTitle: "numberMigrationConfiguration.alerts.accessibleTitle.warning",
  },
  notSupported: {
    tId: "number-migration__not-supported",
    title: "numberMigrationConfiguration.alerts.notSupportedTitle",
    status: "warning",
    message: "numberMigrationConfiguration.alerts.notSupportedMessage",
    accessibleTitle: "numberMigrationConfiguration.alerts.accessibleTitle.warning",
    link: {
      accessibleTitle: "numberMigrationConfiguration.support",
      url: "/foretag/support/overlatelse",
      message: "numberMigrationConfiguration.alerts.notSupportedPage",
    },
  },
  fetchingNumberError: {
    tId: "number-migration__fetch-failed",
    title: "numberMigrationConfiguration.alerts.defaultTitle",
    status: "warning",
    message: "numberMigrationConfiguration.alerts.fetchingNumberError",
    accessibleTitle: "numberMigrationConfiguration.alerts.accessibleTitle.warning",
  },
  generalError: {
    tId: "number-migration__general-error",
    title: "numberMigrationConfiguration.alerts.defaultTitle",
    status: "warning",
    message: "numberMigrationConfiguration.alerts.generalErrorMessage",
    accessibleTitle: "numberMigrationConfiguration.alerts.accessibleTitle.warning",
  },
  organizationSignatoryRequestError: {
    tId: "number-migration__validation-error",
    title: "numberMigrationConfiguration.alerts.defaultTitle",
    status: "warning",
    message: "numberMigrationConfiguration.alerts.organizationSignatoryRequestError",
    accessibleTitle: "numberMigrationConfiguration.alerts.accessibleTitle.warning",
  },
};
