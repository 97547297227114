<template>
  <div>
    <OwnerInformation :type="transferType" />
    <template v-if="isPrivateNumberMigration">
      <div
        class="transfer__number-migration-configuration__head tc-gr__col--12 transfer__input-tooltip"
        t-id="transfer__e-signing-information"
      >
        <telia-text-input
          t-id="portin__private-ssn"
          key="portin__private-ssn"
          :disabled="disableForm"
          :label="t('numberMigrationConfiguration.ssnLabel')"
          required
          :required-error-message="t('numberMigrationConfiguration.validations.required')"
          :pattern="validationPatterns.ssn"
          :pattern-error-message="t('numberMigrationConfiguration.validations.ssn')"
          @input="handleConfigurationUpdate('ssn', $event.target.value)"
        />
        <telia-text-input
          t-id="transfer__private-email"
          key="transfer__private-email"
          :disabled="disableForm"
          :label="t('numberMigrationConfiguration.emailLabel.private')"
          required
          :required-error-message="t('numberMigrationConfiguration.validations.required')"
          type="email"
          :pattern="validationPatterns.email"
          :pattern-error-message="t('numberMigrationConfiguration.validations.email')"
          @input="handleConfigurationUpdate('email', $event.target.value)"
        />
      </div>
    </template>
    <template v-else>
      <telia-text-input
        t-id="portin__organization-number"
        key="portin__organization-number"
        :disabled="disableForm"
        :label="t('numberMigrationConfiguration.orgNrLabel')"
        required
        :required-error-message="t('numberMigrationConfiguration.validations.required')"
        :pattern="validationPatterns.organizationNumber"
        :pattern-error-message="t('numberMigrationConfiguration.validations.organizationNumber')"
        @input="handleConfigurationUpdate('organizationNumber', $event.target.value)"
      />
      <telia-notification
        v-if="isNotMatchingOrg"
        t-id="operator-info"
        variant="inline"
        status="error"
        heading-tag="h3"
        button-aria-label="Operator information"
      >
        <telia-p slot="heading">
          {{ t("numberMigrationConfiguration.alerts.transferNumberNotOwnerHeader") }}
        </telia-p>
        <telia-p slot="content">{{
          t("numberMigrationConfiguration.alerts.transferNumberNotOwnerText")
        }}</telia-p>
      </telia-notification>
      <telia-text-input
        t-id="transfer__organization-ssn"
        key="transfer__organization-ssn"
        :disabled="disableForm"
        :label="t('numberMigrationConfiguration.ssnOrganizationLabel')"
        required
        :required-error-message="t('numberMigrationConfiguration.validations.required')"
        :pattern="validationPatterns.ssn"
        :pattern-error-message="t('numberMigrationConfiguration.validations.ssn')"
        @input="handleConfigurationUpdate('ssn', $event.target.value)"
      />
      <div class="transfer__email-signature transfer__input-tooltip">
        <telia-text-input
          t-id="transfer__organization-email"
          key="transfer__organization-email"
          :disabled="disableForm"
          :label="t('numberMigrationConfiguration.emailLabel.business')"
          required
          :required-error-message="t('numberMigrationConfiguration.validations.required')"
          type="email"
          :pattern="validationPatterns.email"
          :pattern-error-message="t('numberMigrationConfiguration.validations.email')"
          @input="handleConfigurationUpdate('email', $event.target.value)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { validateOrganizationSignatory } from "../services/number-migration-service";
import OrganizationValidationMessage from "./organization-validation-message";
import Tooltip from "./tooltip.vue";
import OwnerInformation from "./owner-information.vue";
import {
  isValidEmailPattern,
  isValidOrganizationNumberPattern,
  isValidSocialSecurityNumberPattern,
  validateNumberConfiguration,
} from "../utils/validations";
import { translateMixin } from "../locale";

export default {
  name: "transfer",
  mixins: [translateMixin],
  components: { OrganizationValidationMessage, Tooltip, OwnerInformation },
  props: {
    disableForm: { type: Boolean },
    organization: { type: String },
    scopeId: { type: String },
  },
  data: () => ({
    error: {
      organizationSignatoryRequest: false,
    },
    validationPatterns: {
      email: isValidEmailPattern.source,
      organizationNumber: isValidOrganizationNumberPattern.source,
      ssn: isValidSocialSecurityNumberPattern.source,
    },
    transferConfiguration: {
      email: null,
      organizationNumber: null,
      ssn: null,
    },
    isMatchingOrg: false,
    lastValidatedSignatory: { ssn: null, organizationNumber: null },
    validSignatory: null,
    pendingSignatoryValidation: false,
  }),
  async mounted() {
    this.emitConfigurationState();
  },
  computed: {
    isNotMatchingOrg() {
      const ownerOrgNumber = this.organization.slice(2);
      return (
        this.transferConfiguration.organizationNumber !== ownerOrgNumber &&
        isValidOrganizationNumberPattern.test(this.transferConfiguration.organizationNumber)
      );
    },
    disableValidateSignatoryButton() {
      return (
        this.validSignatory ||
        !validateNumberConfiguration(
          "organizationNumber",
          this.transferConfiguration.organizationNumber
        ) ||
        !validateNumberConfiguration("ssn", this.transferConfiguration.ssn) ||
        this.pendingSignatoryValidation ||
        !this.isSignatoryInputUpdated
      );
    },
    hasOrganizationSignatoryRequestError() {
      return (
        this.error.organizationSignatoryRequest &&
        this.validSignatory == null &&
        !this.pendingSignatoryValidation
      );
    },
    hasValidSignatoryResult() {
      return this.validSignatory !== null;
    },
    isPrivateNumberMigration() {
      return !this.organization;
    },
    transferType() {
      return this.isPrivateNumberMigration ? "PRIVATE" : "BUSINESS";
    },
    isSignatoryInputUpdated() {
      return (
        this.transferConfiguration.ssn !== this.lastValidatedSignatory.ssn ||
        this.transferConfiguration.organizationNumber !==
          this.lastValidatedSignatory.organizationNumber
      );
    },
    isValid() {
      if (this.isPrivateNumberMigration) {
        const { organizationNumber, ...privateConfig } = this.transferConfiguration;
        const privateValidation = {
          ssn: isValidSocialSecurityNumberPattern.source,
          email: isValidEmailPattern,
        };

        return Object.keys(privateValidation)
          .map((key) => validateNumberConfiguration(key, privateConfig[key]))
          .every(Boolean);
      } else {
        return (
          Object.keys(this.validationPatterns)
            .map((key) => validateNumberConfiguration(key, this.transferConfiguration[key]))
            .every(Boolean) && !this.isNotMatchingOrg
        );
      }
    },
  },
  methods: {
    emitConfigurationState() {
      this.$emit("configuration-state", {
        configuration: { ...this.transferConfiguration, organizationNumber: this.organization },
        valid: this.isValid,
      });
    },
    handleConfigurationUpdate(key, value) {
      this.transferConfiguration[key] = value;
      this.emitConfigurationState();
    },
    emitSignatoryRequestError() {
      this.$emit("signatory-request-error", this.hasOrganizationSignatoryRequestError);
    },
    resetSignatoryValidation() {
      this.error.organizationSignatoryRequest = false;
      this.validSignatory = null;
      this.lastValidatedSignatory = { ssn: null, organizationNumber: null };
    },
    validateOrganizationSignatoryRequest() {
      this.validSignatory = null;
      this.pendingSignatoryValidation = true;
      this.error.organizationSignatoryRequest = false;

      validateOrganizationSignatory(
        this.transferConfiguration.organizationNumber,
        this.transferConfiguration.ssn,
        this.scopeId
      )
        .then((res) => {
          this.validSignatory = res.valid;
        })
        .catch(() => {
          this.error.organizationSignatoryRequest = true;
        })
        .finally(() => {
          this.pendingSignatoryValidation = false;
          this.lastValidatedSignatory = {
            organizationNumber: this.transferConfiguration.organizationNumber,
            ssn: this.transferConfiguration.ssn,
          };
        });
    },
  },
  watch: {
    hasOrganizationSignatoryRequestError: "emitSignatoryRequestError",
  },
};
</script>

<style lang="scss" scoped>
.transfer {
  &__input-tooltip {
    position: relative;
    &__tooltip {
      position: absolute;
      right: 1rem;
    }
  }
}
</style>
