<template>
  <div class="migration-date">
    <telia-date-picker
      :label="t('numberMigrationConfiguration.dateLabel')"
      :localization="dateLocalization"
      :value="selectedDate"
      :max="maxDate"
      :min="nextWorkday"
      :isDateDisabled="isDateDisabled"
      @vocaChange="handleDateSelection"
    />
  </div>
</template>

<script setup lang="ts">
import { translate, currentLanguage } from "@telia/b2b-i18n";
import { computed, ref } from "vue";
import moment from "moment";

interface Props {
  holidays: string[];
  nextWorkday: string;
}

const props = defineProps<Props>();
const maxDate = moment().add(90, "days").format("YYYY-MM-DD");

const emit = defineEmits<{
  (e: "set-date", value: string): void;
}>();

const t = translate;
const selectedDate = ref<string>(props.nextWorkday);
function isDateDisabled(date: Date) {
  if (date.getDay() === 0 || date.getDay() === 6) {
    return true;
  }

  const parsedDate = date.toISOString().split("T")[0];
  if (props.holidays.includes(parsedDate)) {
    return true;
  }
  return false;
}

function handleDateSelection(e: CustomEvent<{ value: string }>) {
  selectedDate.value = e.detail.value;
  emit("set-date", selectedDate.value);
}

const dateLocalization = computed(() => {
  return {
    buttonLabel: "button",
    placeholder: "placeholder",
    selectedDateMessage: "selected date",
    prevMonthLabel: "prevMonth",
    nextMonthLabel: "nextMonth",
    monthSelectLabel: "monthSelect",
    yearSelectLabel: "yearSelect",
    closeLabel: "closeLabel",
    calendarHeading: "calendarHeading",
    dayNames: [
      t("numberMigrationConfiguration.datePicker.days.sunday"),
      t("numberMigrationConfiguration.datePicker.days.monday"),
      t("numberMigrationConfiguration.datePicker.days.tuesday"),
      t("numberMigrationConfiguration.datePicker.days.wednesday"),
      t("numberMigrationConfiguration.datePicker.days.thursday"),
      t("numberMigrationConfiguration.datePicker.days.friday"),
      t("numberMigrationConfiguration.datePicker.days.saturday"),
    ],
    monthNames: [
      t("numberMigrationConfiguration.datePicker.months.jan"),
      t("numberMigrationConfiguration.datePicker.months.feb"),
      t("numberMigrationConfiguration.datePicker.months.mar"),
      t("numberMigrationConfiguration.datePicker.months.apr"),
      t("numberMigrationConfiguration.datePicker.months.may"),
      t("numberMigrationConfiguration.datePicker.months.jun"),
      t("numberMigrationConfiguration.datePicker.months.jul"),
      t("numberMigrationConfiguration.datePicker.months.aug"),
      t("numberMigrationConfiguration.datePicker.months.sep"),
      t("numberMigrationConfiguration.datePicker.months.oct"),
      t("numberMigrationConfiguration.datePicker.months.nov"),
      t("numberMigrationConfiguration.datePicker.months.dec"),
    ],
    monthNamesShort: [
      t("numberMigrationConfiguration.datePicker.monthsShort.jan"),
      t("numberMigrationConfiguration.datePicker.monthsShort.feb"),
      t("numberMigrationConfiguration.datePicker.monthsShort.mar"),
      t("numberMigrationConfiguration.datePicker.monthsShort.apr"),
      t("numberMigrationConfiguration.datePicker.monthsShort.may"),
      t("numberMigrationConfiguration.datePicker.monthsShort.jun"),
      t("numberMigrationConfiguration.datePicker.monthsShort.jul"),
      t("numberMigrationConfiguration.datePicker.monthsShort.aug"),
      t("numberMigrationConfiguration.datePicker.monthsShort.sep"),
      t("numberMigrationConfiguration.datePicker.monthsShort.oct"),
      t("numberMigrationConfiguration.datePicker.monthsShort.nov"),
      t("numberMigrationConfiguration.datePicker.monthsShort.dec"),
    ],
    locale: currentLanguage(),
  };
});
</script>

<style lang="scss">
@import "~@teliads/components/foundations/spacing/variables";
.migration-date {
  margin-top: $telia-spacing-24;
  margin-bottom: $telia-spacing-24;
}
.telia-date-picker__header {
  width: unset !important;
}
</style>
