export const isValidOrganizationNumberPattern = new RegExp(
  /^(16|18|19|20)?([\d]{10}|([\d]{6}-[\d]{4}))$/
);

export const isValidSocialSecurityNumberPattern = new RegExp(
  /^(19|20)([\d]{10}|([\d]{6}-[\d]{4}))$/
);

export const isValidPhoneNumberPattern = new RegExp(
  /^((([+]|00)?46)\s?(7)|07)[02369](((\d(-|\s)*){7})|(((-|\s)*\d){7}))$/
);

export const isValidEmailPattern = new RegExp(/[\w\-._]+@[\w\-._]+\.\w{2,10}/);

export function validateNumberConfiguration(key, field) {
  if (key === "desiredDeliveryDate") {
    return true;
  }
  return validationMap[key].test(field);
}

const validationMap = {
  msisdn: isValidPhoneNumberPattern, // TODO msisdn validation
  email: isValidEmailPattern,
  ssn: isValidSocialSecurityNumberPattern,
  organizationNumber: isValidOrganizationNumberPattern,
};
