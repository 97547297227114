<template>
  <telia-icon
    t-id="tooltip-icon"
    class="tooltip"
    :name="icon"
    :size="iconSize"
    @mouseenter="openTooltip"
    @mouseleave="closeTooltipDebounced"
  >
    <transition name="fade">
      <div t-id="tooltip-textbox" v-show="open" class="tooltiptext" :style="cssVars">
        <slot slot:content></slot>
      </div>
    </transition>
  </telia-icon>
</template>

<script>
export default {
  name: "Tooltip",
  props: {
    icon: { type: String, default: "info" },
    iconSize: { type: String, default: "sm" },
    width: { type: String, default: "300" },
    textLeft: { type: Object },
    textRight: {
      type: Object,
      default: () => ({
        boxRight: "-70",
        arrowRight: "20",
      }),
    },
  },
  data: () => ({
    open: false,
    tooltipTimeout: null,
  }),
  computed: {
    cssVars() {
      let common = { "--box-max-width": this.width + "px" };
      if (this.textLeft) {
        return {
          ...common,
          "--text-box-left": this.textLeft?.boxLeft ? this.textLeft.boxLeft + "%" : null,
          "--text-arrow-left": this.textLeft?.arrowLeft ? this.textLeft.arrowLeft + "px" : null,
        };
      } else {
        return {
          ...common,
          "--text-box-right": this.textRight?.boxRight ? this.textRight.boxRight + "%" : null,
          "--text-arrow-right": this.textRight?.arrowRight
            ? this.textRight.arrowRight + "px"
            : null,
        };
      }
    },
  },
  methods: {
    openTooltip() {
      if (this.tooltipTimeout) {
        clearTimeout(this.tooltipTimeout);
      }
      this.open = true;
    },
    closeTooltip() {
      this.open = false;
    },
    closeTooltipDebounced() {
      this.tooltipTimeout = setTimeout(this.closeTooltip, 100);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";

.tooltip {
  position: relative;
  display: inline-block;
  margin: 0 4px;

  &:focus-within {
    .tooltiptext {
      display: inline !important;
    }
  }

  .tooltiptext {
    width: max-content;
    max-width: var(--box-max-width);
    background-color: $telia-purple-800;
    color: $telia-white;
    border-radius: 6px;
    padding: $telia-spacing-12;
    position: absolute;
    z-index: 2;
    top: 120%;
    left: var(--text-box-left);
    right: var(--text-box-right);
    font-size: initial;

    @media screen and (max-width: 768px) {
      width: 200px;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: var(--text-arrow-left);
      right: var(--text-arrow-right);
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $telia-purple-850;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transform: translateY(0);
  transition: all 150ms ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY($telia-spacing-12);
}
</style>
